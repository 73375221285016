<template>
    <div>
      <b-container
        class="m-0 w-100 mt-1"
        fluid
      >        
       
        <b-nav
          card-header
          pills
          class="m-0"
        >          
          <b-nav-item
            :to="{ name: 'crm-settings-sms' }"
            exact
            exact-active-class="sub-tab-nav"
            :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"            
          >
            FINANCIAL
          </b-nav-item>
        </b-nav>
        <b-card class="border-top-info border-3 border-table-radius px-0">
          <router-view :key="key" />
        </b-card>
      </b-container>
      <create-edit-motive-modal
        v-if="createEditMotiveModalOn"
        :mood="mood"
        @reload="key++"
        @close="closeEditMotiveModalOn"
      />
    </div>
  </template>
  
  <script>
  import CreateEditMotiveModal from '@/views/social-network/views/settings/modals/CreateEditMotiveModal.vue'
  
  export default {
    components: {
      CreateEditMotiveModal,
    },
    data() {
      return {
        createEditMotiveModalOn: false,
        motiveToSend: {},
        mood: 0,
        key: 1,
      }
    },
    created() {
  
    },
    methods: {
      openEditMotiveModalOn() {
        this.createEditMotiveModalOn = true
        this.mood = 1
      },
      closeEditMotiveModalOn() {
        this.createEditMotiveModalOn = false
      },
    },
  }
  </script>
  
  <style>
  
  </style>
  